import {Model} from "../Model"
import {Gate} from "../Gate"

export class GateListener {
    constructor(private model: Model, private gate: Gate) {
        this.onGate = this.onGate.bind(this)
        gate.addListener(this.onGate)
    }

    private onGate(response) {
        let data = response.data
        if (data.authorized !== undefined) {
            this.model.authorized = data.authorized
        }
        if (data.token) {
            this.model.token = data.token
        }
        if (data.clients) {
            let clients = Object.assign([], data.clients)
            clients.unshift("all")
            this.model.clients = clients
        }
        if (data.applications) {
            this.model.applications = data.applications
        }
        this.model.dispatch()
    }
}
