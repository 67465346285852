import * as React from "react"
import {Component} from "react"
import {Filter, FilterParameters} from "../Filter/Filter"
import {Applications, Model} from "../../../../Model"
import {Gate} from "../../../../Gate"
import "./style.css"
import {Row} from "../Row"
import {Paginator} from "../../Paginator/Paginator"
import {FilterWrapper} from "../Filter/FilterWrapper"

import $ from "jquery"
import "bootstrap/dist/js/bootstrap.bundle.min"
import SignaturePad from "signature_pad"
import {Application} from "../../../../interfaces"

interface Properties {
    model: Model
    gate: Gate
}

interface State {
    applications: Applications
    filter: FilterParameters
    signature: any
    applicationSelected: any
}

export class ApplicationsList extends Component<Properties, State> {
    constructor(props) {
        super(props)
        this.state = {
            applications: this.props.model.applications,
            filter: this.props.model.applications.filter,
            signature: null,
            applicationSelected: null
        }
    }

    public componentDidMount() {
        this.onModel = this.onModel.bind(this)
        this.props.model.addListener(this.onModel)
        this.props.gate.getApplications(
            this.props.model.applications.filter,
            this.props.model.applications.sort
        )
        let canvas = document.getElementById("canvas_staff_signature") as HTMLCanvasElement
        this.setState({signature: new SignaturePad(canvas)})
    }

    public render() {
        let rows = this.state.applications.applications.map((application, index) => {
            return (
                <Row
                    application={application}
                    onApplicationChange={this.applicationChange.bind(this)}
                    gate={this.props.gate}
                    key={index}
                />
            )
        })
        return (
            <div className="">
                <FilterWrapper model={this.props.model} gate={this.props.gate} />
                <div style={{paddingTop: "20px"}}></div>
                <div>
                    <strong>Total: {this.state.applications.sort.total}</strong>
                </div>
                <div style={{paddingTop: "20px"}}></div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Date</th>
                            <th scope="col">Email</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </table>
                {/*<div style={{paddingTop: "20px"}}></div>*/}
                <div style={{margin: "0 auto"}}>
                    <Paginator
                        onChange={this.onPaginatorChange.bind(this)}
                        total={this.state.applications.sort.totalPages}
                        current={this.state.applications.sort.page}
                    />
                </div>

                <div
                    className="modal fade"
                    id="canvasModal"
                    role="dialog"
                    aria-labelledby="canvasModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="canvasModalLabel">
                                    Staff signature
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <canvas id="canvas_staff_signature"></canvas>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal">
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={this.cleanSignature.bind(this)}>
                                    Clean Signature
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.signApplication.bind(this)}>
                                    Sign
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private applicationChange(application) {
        this.setState({applicationSelected: application})
    }

    private async signApplication(e) {
        console.log(this.state.signature.toDataURL())
        let applicationUpdated: Application = this.state.applicationSelected
        applicationUpdated.staff_signature = this.state.signature.toDataURL()
        try {
            let response = await this.props.gate.updateApplication(applicationUpdated)
            //@ts-ignore
            console.log(response)
            console.log("updated signature")
            if (response.data.errors && Object.keys(response.data.errors).length > 0) {
                console.log(response.data.errors)
            } else {
            }
        } catch (error) {
            console.log("error signature")
        }
    }

    private cleanSignature(e) {
        this.state.signature.clear()
    }

    private onModel() {
        if (this.state.applications == this.props.model.applications) {
            return
        }
        this.setState({applications: this.props.model.applications})
    }

    /*private onFilterChange(filter) {
        this.props.gate.getApplications(filter, this.props.model.applications.sort);
        this.setState({filter});
    }*/

    private onPaginatorChange(page) {
        this.props.model.applications.sort.page = page
        this.props.gate.getApplications(
            this.props.model.applications.filter,
            this.props.model.applications.sort
        )
    }
}
