import * as axios from "axios"
import {EventDispatcher} from "./EventDispatcher"
import {FilterParameters} from "./components/dashboard/list/Filter/Filter"
import {SortParams} from "./Model"
import * as querystring from "querystring"
import {Application} from "./interfaces"

export class Gate extends EventDispatcher {
    private domain: string

    constructor() {
        super()
        this.domain = process.env.REACT_APP_API_DOMAIN
    }

    public setToken(token) {
        //@ts-ignore
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
    }

    public async login(email: string, password: string): Promise<any> {
        let url = `${this.domain}/v1/api/staff-login`
        //@ts-ignore
        let response = await axios.post(url, {
            email: email,
            password: password,
            client_id: "bellus"
        })
        return response
    }

    public async updateApplication(application: Application): Promise<any> {
        let url = `${this.domain}/v1/api/update-application`
        //@ts-ignore
        let response = await axios.post(url, application)
        return response
    }

    public async refreshToken(token: string | null): Promise<any> {
        let url = `${this.domain}/v1/api/refresh-token`

        const header = `Authorization: Bearer ${token}`
        //@ts-ignore
        let response = await axios.post(url, {headers: {header}})

        this.dispatch(response)

        return response
    }

    public async getClients() {
        let url = `${this.domain}/v1/api/clients`
        //@ts-ignore
        let response = await axios.get(url)
        return response
    }

    public async getApplications(filter: FilterParameters, sort: SortParams) {
        let queryParams = {
            page: sort.page,
            pageSize: sort.pageSize,
            client_id: "bellus",
            campus: filter.selectedCampus,
            dateFrom: filter.dateFrom,
            dateTo: filter.dateTo,
            text: filter.text
        }
        let query = querystring.stringify(queryParams)

        let url = `${this.domain}/v1/api/applications?${query}`

        //@ts-ignore
        let response = await axios.get(url)

        this.dispatch(response)

        return response
    }

    public async getForms() {
        let url = `${this.domain}/v1/api/forms`

        //@ts-ignore
        let response = await axios.get(url)

        this.dispatch(response)

        return response
    }

    public async runBackgroundJob(applicationId: number): Promise<any> {
        let url = `${this.domain}/v1/api/run-background-job`

        //@ts-ignore
        let response = await axios.post(url, {applicationId})

        return response
    }
}
