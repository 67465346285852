import {Component} from "react"
import * as React from "react"
import {Application} from "../../../interfaces"
import {Gate} from "../../../Gate"

interface Properties {
    application: Application
    gate: Gate
    onApplicationChange: (e) => void
}

export class Row extends Component<Properties> {
    public render() {
        let a = this.props.application
        let url = `https://simple.klassdocs.com/v1/check/${a.applicationId}`
        let date = ""
        if (a.created_at) {
            date = new Date(a.created_at).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric"
            })
        }
        return (
            <tr>
                <th scope="row">{a.applicationId}</th>
                <td>{date}</td>
                <td>{a.email}</td>
                <td>{a.first_name}</td>
                <td>{a.last_name}</td>
                <td>
                    <a href={url} target="_blank">
                        Check
                    </a>
                </td>
                <td>
                    <a href="#" onClick={this.onRegenerateClick.bind(this)}>
                        Regenerate
                    </a>
                </td>
                <td>
                    <button
                        type="button"
                        className="btn btn-primary"
                        data-toggle="modal"
                        data-target="#canvasModal"
                        onClick={this.selectApplication.bind(this)}>
                        Signature
                    </button>
                </td>
            </tr>
        )
    }

    private selectApplication() {
        this.props.onApplicationChange(this.props.application)
    }

    private async onRegenerateClick(e) {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()

        console.log(this.props.application.applicationId)
        let r = await this.props.gate.runBackgroundJob(this.props.application.applicationId)
        console.log(r)
        alert(r.data.message)
    }
}
