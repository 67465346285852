import * as React from "react"
import {Component} from "react"
import "bootstrap/dist/css/bootstrap.css"
import {Model} from "../../Model"
import "./style.css"
import {Gate} from "../../Gate"
import {LoginPage} from "../auth/LoginPage"
import {MainPage} from "../MainPage"

interface Properties {
    model: Model
    gate: Gate
}

interface State {
    globalPreloader: boolean
    authorized: boolean
}

export class App extends Component<Properties, State> {
    constructor(props) {
        super(props)
        this.state = {
            globalPreloader: false,
            authorized: this.props.model.authorized
        }
        this.onModel = this.onModel.bind(this)
        this.props.model.addListener(this.onModel)
    }

    public render() {
        const cover = (
            <div>
                <div className="loader"></div>
                <div className="cover"></div>
            </div>
        )
        return (
            <div className="container">
                {this.state.globalPreloader && cover}
                {!this.state.authorized && (
                    <LoginPage model={this.props.model} gate={this.props.gate} />
                )}
                {this.state.authorized && (
                    <MainPage model={this.props.model} gate={this.props.gate} />
                )}
            </div>
        )
    }

    private onModel() {
        if (this.state.globalPreloader != this.props.model.globalPreloader) {
            this.setState({globalPreloader: this.props.model.globalPreloader})
        }
        if (this.state.authorized != this.props.model.authorized) {
            this.setState({authorized: this.props.model.authorized})
        }
    }
}
