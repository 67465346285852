import {Component, SyntheticEvent} from "react"
import * as React from "react"
import * as classNames from "classnames"

interface State {
    current: number
}

interface Props {
    onChange: Function
    total: number
    current: number
}

export class Paginator extends Component<Props, State> {
    private readonly offset = 15

    constructor(props) {
        super(props)
        this.state = {current: props.current}
    }

    private onClick(e: SyntheticEvent<any>) {
        e.preventDefault()
        let element = e.target as HTMLAnchorElement
        let page = parseInt(element.getAttribute("data-page") as string)

        this.setState({current: page})
        this.props.onChange(page)
    }

    public render() {
        let items: any[] = []
        let dots = (
            <li className="page-item" key={-1}>
                <a className="page-link">...</a>
            </li>
        )
        let hasDots = false

        for (let i = 1; i <= this.props.total; i++) {
            if (this.offset < i && i <= this.props.total - this.offset) {
                if (!hasDots) {
                    items.push(dots)
                    hasDots = true
                }
                continue
            }
            let item = (
                <li className={classNames("page-item", {active: i == this.state.current})} key={i}>
                    <a
                        className="page-link"
                        href="#"
                        data-page={i}
                        onClick={this.onClick.bind(this)}>
                        {i}
                    </a>
                </li>
            )
            items.push(item)
        }

        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                    <li className="page-item" key={0}>
                        <a
                            className="page-link"
                            href="#"
                            aria-label="Previous"
                            data-page={1}
                            onClick={this.onClick.bind(this)}>
                            &laquo;
                        </a>
                    </li>
                    {items}
                    <li className="page-item" key={this.props.total + 1}>
                        <a
                            className="page-link"
                            href="#"
                            aria-label="Next"
                            data-page={this.props.total}
                            onClick={this.onClick.bind(this)}>
                            &raquo;
                        </a>
                    </li>
                </ul>
            </nav>
        )
    }
}
