import {Model} from "../Model"
import {Gate} from "../Gate"

export class Actions {
    public static createModel(): Model {
        let model = new Model()

        model.globalPreloader = false
        model.page = "applications"
        model.clients = []
        model.applications = {
            applications: [],
            filter: {
                selectedClient: "all",
                selectedCampus: "all",
                dateFrom: "",
                dateTo: "",
                text: ""
            },
            sort: {
                total: 0,
                pageSize: 10,
                page: 1,
                totalPages: 1
            }
        }
        model.token = window.localStorage.getItem("token")

        //@ts-ignore
        window.model = model

        return model
    }

    public static start(gate: Gate, model: Model) {
        gate.refreshToken(model.token)
    }
}
