import * as React from "react"
import {Component} from "react"
import {Model} from "../../Model"
import {Gate} from "../../Gate"
import {LoginFormProperties} from "./LoginForm"

interface Properties {
    targetComponent
    model: Model
    gate: Gate
}

interface State {
    isLoading: boolean
    errors: any
    globalError?: string
}

export class LoginFormWrapper extends Component<Properties, State> {
    constructor(props) {
        super(props)

        this.state = {isLoading: false, errors: {}}
    }

    public render() {
        return React.createElement<LoginFormProperties>(this.props.targetComponent, {
            isLoading: this.state.isLoading,
            onChange: this.onChange.bind(this),
            errors: this.state.errors,
            globalError: this.state.globalError
        })
    }

    private async onChange(email, password) {
        this.setState({globalError: undefined, errors: {}, isLoading: true})
        try {
            let response = await this.props.gate.login(email, password)
            //@ts-ignore
            if (response.data.errors && Object.keys(response.data.errors).length > 0) {
                console.log(response.data.errors)
                this.setState({errors: response.data.errors})
            } else {
                if (response.data.authorized) {
                    this.props.model.authorized = true
                    this.props.model.token = response.data.token
                    this.props.model.dispatch()
                } else {
                    this.setState({globalError: "Wrong email or password"})
                }
            }
        } catch (error) {
            console.log(error)
            console.log("Global error")
            this.setState({globalError: "Can not connect to server"})
        } finally {
            this.setState({isLoading: false})
        }
    }
}
