import * as React from "react"
import {Component, SyntheticEvent} from "react"
import "../ApplicationsList/style.css"

export interface FilterParameters {
    selectedClient: string
    selectedCampus: string
    dateFrom: string
    dateTo: string
    text: string
}

interface Properties {
    clients: string[]
    onChange: (filter: FilterParameters) => void
}

/*interface State {

    selectedClient: string;
    dateFrom: string;
    dateTo: string;
    text: string;
}*/

/**
 * Filter is a pure UI component
 * It knows nothing about Model or Gate
 * It has only input (array of client Ids)
 * and output (object representation of current selected filter)
 */

// TODO Should update state via properties
// TODO filter and sort from server response should update cFilter and Paginator state (via props)
export class Filter extends Component<Properties, FilterParameters> {
    constructor(props) {
        super(props)
        this.state = {
            selectedClient: "bellus",
            dateFrom: "",
            dateTo: "",
            text: "",
            selectedCampus: "all"
        }
    }

    public componentDidMount() {}

    public componentWillUnmount() {}

    public componentDidUpdate() {}

    public render() {
        let options = this.props.clients.map((item: string, index) => {
            return (
                <option key={index} value={item}>
                    {item}
                </option>
            )
        })
        return (
            <form>
                <div className="filter-panel">
                    <div className="row">

                        <div className="col-md-3">
                            <label htmlFor="client-dropdown">Campus</label>
                            <select
                                className="form-control"
                                name="selectedCampus"
                                id="campus-dropdown"
                                onChange={this.onChange.bind(this)}>
                                <option value="all">Any</option>
                                <option value="Poway">Poway</option>
                                <option value="Chula Vista">Chula Vista</option>
                                <option value="Manhattan">Manhattan</option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="from">From</label>
                            <input
                                type="date"
                                name="dateFrom"
                                className="form-control"
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="to">To</label>
                            <input
                                type="date"
                                name="dateTo"
                                className="form-control"
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        <div className="col-md-5">
                            <label>Name or Email</label>
                            <input
                                type="text"
                                name="text"
                                className="form-control"
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    private onChange(e: SyntheticEvent<any>) {
        let target = e.target as HTMLInputElement
        const {name, value} = target
        let entry = {}
        entry[target.name] = target.value
        console.log(target.name, target.value)
        this.setState(entry)
        /*let filterParams: FilterParameters = {
            selectedClient: this.state.selectedClient,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo
        };*/

        let filterParams: FilterParameters = {
            ...this.state
        }
        console.log(filterParams)
        filterParams[target.name] = target.value
        this.props.onChange(filterParams)
    }
}
