import {EventDispatcher} from "./EventDispatcher"
import {Application} from "./interfaces"
import {FilterParameters} from "./components/dashboard/list/Filter/Filter"

export class Model extends EventDispatcher {
    globalPreloader: boolean
    token: string | null
    page: "applications" | "forms"
    authorized: boolean
    clients
    applications: Applications

    constructor() {
        super()
    }
}

export interface Applications {
    applications: Application[]
    filter: FilterParameters
    sort: SortParams
}

export interface SortParams {
    total: number
    pageSize: number
    page: number
    totalPages
}
