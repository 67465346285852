import * as React from "react"
import * as ReactDOM from "react-dom"
import {App} from "./components/App/App"
import {Gate} from "./Gate"
import {TokenListener} from "./listeners/TokenListener"
import {Actions} from "./actions/Actions"
import {GateListener} from "./listeners/GateListener"

let model = Actions.createModel()
let gate = new Gate()
new GateListener(model, gate)
new TokenListener(model, gate)
model.dispatch()
Actions.start(gate, model)

ReactDOM.render(<App model={model} gate={gate} />, document.getElementById("root"))
model.dispatch("")
