import * as React from "react"
import {Component, ReactNode} from "react"
import {Model} from "../Model"

interface Properties {
    model: Model
}

export class NavigationBar extends Component<Properties> {
    constructor(props) {
        super(props)
        this.onModel = this.onModel.bind(this)
        this.props.model.addListener(this.onModel)
    }

    public render(): ReactNode {
        return (
            <div className="navbar navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand" href="/">
                    <strong>Klassapp</strong>
                </a>
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <a
                            className="nav-link"
                            data-name="applications"
                            href="#"
                            onClick={this.onClick.bind(this)}>
                            Applications
                        </a>
                    </li>
                </ul>
                <ul className="nav navbar-nav navbar-right">
                    <li className="nav-item">
                        <a className="nav-link" href="/login">
                            Sign Out
                        </a>
                    </li>
                </ul>
            </div>
        )
    }

    private onModel() {}

    private onClick(e) {
        e.preventDefault()
        let name = e.target.getAttribute("data-name")
        console.log(name)
        this.props.model.page = name
        this.props.model.dispatch()
    }
}
