import * as React from "react"
import {Component} from "react"
import {Model} from "../../Model"
import {Gate} from "../../Gate"
import {LoginFormWrapper} from "./LoginFormWrapper"
import {LoginForm} from "./LoginForm"

interface Properties {
    model: Model
    gate: Gate
}

export class LoginPage extends Component<Properties> {
    constructor(props) {
        super(props)
    }

    public render() {
        return (
            <div className="row" style={{marginTop: "20px;"}}>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <img
                                    src="/bellus-logo.png"
                                    style={{width: "150px;"}}
                                    className="rounded"
                                    alt="Bellus Academy"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 offset-md-4">
                            <LoginFormWrapper
                                model={this.props.model}
                                gate={this.props.gate}
                                targetComponent={LoginForm}></LoginFormWrapper>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
