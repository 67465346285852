import * as React from "react"
import {Component, SyntheticEvent} from "react"
import "../ApplicationsList/style.css"
import {Model} from "../../../../Model"
import {Gate} from "../../../../Gate"
import {Filter, FilterParameters} from "./Filter"

interface Properties {
    model: Model
    gate: Gate
}

interface State {
    clients: string[]
}

/**
 * This class has more high level logic
 * on top of Filter
 * It save filter params to model and send request to Gate
 *
 * Do not add UI here!
 */
export class FilterWrapper extends Component<Properties, State> {
    constructor(props) {
        super(props)
        this.state = {clients: []}
        this.onModel = this.onModel.bind(this)
    }

    public componentDidMount() {
        this.props.model.addListener(this.onModel)
    }

    public componentWillUnmount() {
        this.props.model.removeListener(this.onModel)
    }

    public render() {
        return <Filter clients={this.state.clients} onChange={this.handleFilter.bind(this)} />
    }

    private onModel() {
        this.setState({clients: this.props.model.clients})
    }

    private handleFilter(filter: FilterParameters) {
        this.props.model.applications.filter = filter
        let state = this.state
        this.props.gate.getApplications(
            this.props.model.applications.filter,
            this.props.model.applications.sort
        )
    }
}
