import * as React from "react"
import {Component} from "react"
import {Model} from "../Model"
import {Gate} from "../Gate"
import {LoginFormWrapper} from "./auth/LoginFormWrapper"
import {LoginForm} from "./auth/LoginForm"
import {LoginForm2} from "./auth/LoginForm2"
import {NavigationBar} from "./NavigationBar"
import {ApplicationsList} from "./dashboard/list/ApplicationsList/ApplicationsList"
import {FormsList} from "./FormsList"

interface Properties {
    model: Model
    gate: Gate
}

interface State {
    page: string
}

export class MainPage extends Component<Properties, State> {
    constructor(props) {
        super(props)
        this.state = {page: this.props.model.page}
        this.onModel = this.onModel.bind(this)
        this.props.model.addListener(this.onModel)
    }

    public render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <NavigationBar model={this.props.model} />
                    </div>
                </div>
                <div className="row" style={{marginTop: "20px"}}>
                    <div className="col-md-12">
                        <div className="list-panel">
                            {this.state.page == "applications" && (
                                <ApplicationsList model={this.props.model} gate={this.props.gate} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private onModel() {
        this.setState({page: this.props.model.page})
    }
}
